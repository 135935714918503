/** @jsxImportSource @emotion/react */
import { Theme, css } from '@emotion/react';
import { Add, Close } from '@livechat/design-system-icons';
import {
  Icon,
  Button,
  DesignToken,
  SearchInput,
} from '@livechat/design-system-react-components';
import { motion } from 'framer-motion';
import { useRouter } from 'next/navigation';
import { KeyboardEvent, useRef, useState } from 'react';

import CreateChannelButton from '@teamchat-shared/components/CreateChannelButton';
import { LogoApp as Logo } from '@teamchat-shared/components/Logo';
import UserMenu from '@teamchat-shared/components/UserMenu';
import Box from '@teamchat-shared/design/Box';
import { SidebarItem } from '@teamchat-shared/types/hello';

import SidebarCreateChannel from '../../SidebarCreateChannel';
import { sidebarStyles } from '../styles';

import SidebarList from './SidebarList';

type Props = {
  isVisible: boolean;
  allItems: SidebarItem[];
  setSidebarVisible?: (isVisible: boolean) => void;
  isMobile: boolean;
};

export default function Sidebar({
  isVisible,
  setSidebarVisible,
  allItems,
  isMobile,
}: Props) {
  const router = useRouter();
  const sidebarContainerRef = useRef<HTMLDivElement>(null);

  const [isCreateChannelMode, setIsCreateChannelMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const filteredItems = allItems.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (text: string) => {
    setSearchTerm(text);
    setSelectedIndex(-1);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : filteredItems.length - 1
      );
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) =>
        prevIndex < filteredItems.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      const selectedChannel = filteredItems[selectedIndex];

      if (selectedChannel) {
        router.push(selectedChannel.url);
      }
    }
  };

  const onlyChannels = allItems.filter((item) => item.isChannel);
  const channelsIds = onlyChannels.map((channel) => channel.id);

  return (
    <motion.aside
      initial={isMobile ? {} : { x: '-320px' }}
      animate={isMobile ? {} : { x: 0 }}
      transition={{ duration: 0.1 }}
      css={sidebarStyles}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onBlur={() => {
        setSelectedIndex(-1);
      }}
    >
      <Box
        pt={{
          _: 33,
          md: 15,
        }}
        pb={{
          _: 13,
          md: 16,
        }}
        pl={{
          _: 24,
          md: 22,
        }}
        pr={{
          _: 24,
          md: 22,
        }}
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          css={css`
            position: relative;
          `}
        >
          <Logo />
        </Box>

        <Box
          display={{
            _: 'none',
            md: 'block',
          }}
          position="relative"
        >
          {isCreateChannelMode ? (
            <Button
              css={css`
                border-radius: 14px;
                padding: 9px 10px;
              `}
              size="large"
              icon={
                <Icon
                  size="large"
                  source={Close}
                  css={css`
                    width: 24px;
                    height: 24px;

                    > svg {
                      width: 24px;
                      height: 24px;
                    }
                  `}
                />
              }
              kind="text"
              onClick={() => setIsCreateChannelMode(false)}
            />
          ) : (
            <CreateChannelButton
              setIsCreateChannelMode={setIsCreateChannelMode}
            />
          )}
        </Box>
      </Box>

      <Box position="relative" height="100%">
        {isCreateChannelMode ? (
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
            `}
          >
            <SidebarCreateChannel
              channelsIds={channelsIds}
              onClose={() => setIsCreateChannelMode(false)}
            />
          </div>
        ) : (
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
            `}
          >
            {isVisible && (
              <Box
                pl={22}
                pr={22}
                mb={{
                  _: 4,
                  md: 0,
                }}
              >
                <SearchInput
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  value={searchTerm}
                  size="medium"
                  css={(theme: Theme) => css`
                    position: relative;
                    z-index: 2;
                    background-color: var(
                      ${DesignToken.SurfaceSecondaryDefault}
                    );

                    height: 46px;
                    border-radius: 14px;

                    &[class*='lc-Search-module__search-input___'] {
                      border: 1px solid
                        var(${DesignToken.SurfaceSecondaryDefault});
                    }
                    &[class*='lc-Search-module__search-input___']:hover {
                      border-color: var(${DesignToken.BorderBasicHover});
                    }
                    &[class*='lc-Search-module__search-input___']:focus {
                      border-color: var(${DesignToken.ActionPrimaryDefault});
                    }
                    &[class*='lc-Search-module__search-input--focused___'],
                    &[class*='lc-Search-module__search-input--focused___']:hover {
                      border-color: var(${DesignToken.ActionPrimaryDefault});
                    }

                    @media (min-width: ${theme.breakpoints.md}) {
                      height: 42px;
                    }

                    input {
                      font-size: 18px;

                      @media (min-width: ${theme.breakpoints.md}) {
                        font-size: 14px;
                      }
                    }

                    [class*='lc-Icon-module__icon--primary'] {
                      color: var(${DesignToken.ContentBasicDisabled});
                      > svg {
                        width: 18px;
                        height: 18px;
                      }
                    }
                  `}
                />
              </Box>
            )}

            <Box
              pb={{
                _: 100,
                md: 0,
              }}
              css={css`
                position: absolute;
                width: 100%;
                height: calc(100% - 42px);
              `}
            >
              <SidebarList
                allItems={filteredItems}
                setSidebarVisible={setSidebarVisible}
                sidebarContainerRef={sidebarContainerRef}
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
              />
            </Box>
          </div>
        )}

        {isVisible && (
          <Box position="absolute" left={0} right={0} bottom={0}>
            {!isCreateChannelMode && <UserMenu />}

            <Box
              display={{
                _: 'block',
                md: 'none',
              }}
              position="absolute"
              zIndex={2}
              right="34px"
              bottom="34px"
            >
              {isCreateChannelMode ? null : (
                <Button
                  size="large"
                  icon={
                    <Icon
                      size="large"
                      source={Add}
                      css={css`
                        width: 32px;
                        height: 32px;

                        > svg {
                          width: 32px;
                          height: 32px;
                        }
                      `}
                    />
                  }
                  iconPosition="right"
                  kind="text"
                  onClick={() => setIsCreateChannelMode(true)}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </motion.aside>
  );
}
