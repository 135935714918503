export enum OnboardingInputType {
  Text = 'text',
  Url = 'url',
  Button = 'button',
}

export type ResponseOnboardingMessage = {
  messages: string[];
  awaitsResponse: boolean;
  input: {
    type?: OnboardingInputType;
    label?: string;
    delay?: number;
    labelCompleted?: string;
    onAction?: () => void;
  };
};

export type OnboardingMessage = {
  type: 'text' | 'input';
  inputData?: any;
  message: string;
  delay?: number;
  id?: string;
  validator?: (value: string) => string;
  onSubmit?: (value: string) => void;
};
