/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PowerSettings } from '@livechat/design-system-icons';
import {
  Icon,
  Button,
  Tooltip,
} from '@livechat/design-system-react-components';
import { motion } from 'framer-motion';

import { useToasts } from '@teamchat-shared/contexts/toasts';
import Avatar from '@teamchat-shared/design/Avatar';
import Box from '@teamchat-shared/design/Box';
import Text from '@teamchat-shared/design/Text';
import { useAccount } from '@teamchat-shared/hooks/useAccount';
import { logout } from '@teamchat-shared/lib/auth';
import { useGetAgent } from '@teamchat-shared/queries/agents';
import { sidebarWidth } from '@teamchat-shared/styles/theme';

const UserMenu = () => {
  const { notifyError } = useToasts();
  const account = useAccount();
  const email = account?.gaccProfile?.email || '';
  const name = account?.gaccProfile?.name || '';
  const avatar = account?.gaccProfile?.avatar || '';

  const { data: agent } = useGetAgent(email);

  if (!agent) {
    return null;
  }

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      notifyError('An error occurred.', '', false);
    }
  };

  const initialWidth = 68;
  const hoverWidth = sidebarWidth - 48;

  const variants = {
    initial: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      width: `${initialWidth}px`,
    },
    hover: {
      boxShadow: `0 2px 10px rgba(19, 19, 23,0.18)`,
      backgroundColor: '#fff',
      width: `${hoverWidth}px`,
    },
  };

  return (
    <Box m="4px 24px 24px 24px">
      <motion.div
        layout
        initial="initial"
        whileHover="hover"
        variants={variants}
        style={{ borderRadius: '16px' }}
        css={css`
          display: flex;
          padding: 14px;
          gap: 12px;
          position: relative;
          z-index: 200;
          height: 70px;
        `}
      >
        <Avatar
          src={avatar}
          size="large"
          text={name}
          status={agent?.status}
          type={avatar ? 'image' : 'text'}
          css={css`
            width: 42px;
            height: 42px;
          `}
        />
        <motion.div
          variants={{
            initial: {
              opacity: 0,
            },
            hover: {
              opacity: 1,
            },
          }}
          transition={{ duration: 0.3 }}
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
          `}
        >
          <Box display="flex" flexDirection="column" width="calc(100% - 44px)">
            <Text
              bold
              css={css`
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              `}
            >
              {name}
            </Text>
            <Text
              css={css`
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 16px;
              `}
            >
              {email}
            </Text>
          </Box>

          <Box>
            <Tooltip
              floatingStrategy="fixed"
              css={css`
                *[class^='lc-Tooltip-module__tooltip__arrow___'] {
                  display: none;
                }

                box-shadow: 0 2px 10px rgba(19, 19, 23, 0.18);
              `}
              placement="top"
              triggerRenderer={
                <Button
                  size="large"
                  kind="text"
                  icon={
                    <Icon
                      source={PowerSettings}
                      size="large"
                      css={css`
                        width: 24px;
                        height: 24px;

                        > svg {
                          width: 24px;
                          height: 24px;
                        }
                      `}
                    />
                  }
                  onClick={handleLogout}
                />
              }
            >
              Log out
            </Tooltip>
          </Box>
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default UserMenu;
