/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useProductSwitcher } from '@livechat/design-system-react-components';
import { ProductSwitcher } from '@livechat/design-system-react-components';
import { Inter } from 'next/font/google';

import Box from '@teamchat-shared/design/Box';
import Text from '@teamchat-shared/design/Text';
import { useAccount } from '@teamchat-shared/hooks/useAccount';
import { useProductData } from '@teamchat-shared/hooks/useProductData';
import { config } from '@teamchat-shared/lib/config';

import LogoLight from './LogoLight';

const inter = Inter({ subsets: ['latin'] });

type Props = {
  height?: string;
};

const LogoApp = ({ height = '42px' }: Props) => {
  const account = useAccount();
  const { installedProducts, subscriptions } = useProductData();

  const { products } = useProductSwitcher({
    env: config.env,
    installedProducts,
    subscriptions,
    organizationId: account?.gaccProfile?.organization?.id,
  });

  return (
    <Box
      height={height}
      display="flex"
      alignItems="center"
      position="relative"
      zIndex={100}
      css={css`
        > div {
         > div {
          background-color: white;
          color: #131317;
          border-width: 0;

          *[class^='lc-ProductTile-module__product-tile___'] {
            border-width: 0;
          }

          *[class^='lc-ProductTile-module__product-tile__third-layer___'] {
            display: none;
          }

          *[class^='lc-ProductTile-module__product-tile__second-layer___'] {
            display: none;
          }
         }
        `}
    >
      <ProductSwitcher
        mainProductId="teamchat"
        productOptions={products}
        isDarkMode={false}
      />

      <Box ml="7px">
        <Text
          fontWeight="600"
          fontSize="18px"
          css={css`
            font-family: ${inter.style.fontFamily} !important;
          `}
        >
          TeamChat
        </Text>
      </Box>
    </Box>
  );
};

const LogoLanding = ({ height = '42px' }: Props) => {
  return (
    <Box height={height} display="flex" alignItems="center">
      <LogoLight />
    </Box>
  );
};

export { LogoApp, LogoLanding };
