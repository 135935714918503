/* eslint-disable no-console */
import {
  HelloAccountData,
  TextChatChannelTypeEnum,
} from '@livechat/hello-utils';
import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { env } from 'next-runtime-env';
import { useEffect, useMemo, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';

import { AgentData, agentKeys } from '@teamchat-shared/queries/agents';
import {
  ChannelData,
  channelsKeys,
  fetchChannels,
} from '@teamchat-shared/queries/channels';
import { config } from '../lib/config';

interface AccountStatusUpdatedEvent {
  gaccAccountId: string;
  status: AccountStatusEnum;
}

enum AccountStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
}

type Props = {
  socket: Socket | undefined;
  hasImportFinished: boolean;
};

export const useTeamChatSocket = (
  token: string,
  account: HelloAccountData
): Props => {
  const [hasImportFinished, setHasImportFinished] = useState(false);
  const queryClient = useQueryClient();
  const socketUrl = `${config.tcApiURL}/ws`;

  const socket: Socket | undefined = useMemo(() => {
    if (token && account) {
      return io(socketUrl, {
        auth: { token },
        transports: ['websocket'],
        path: '/teamchat/ws/socket.io',
      });
    }
  }, [token, account, socketUrl]);

  useEffect(() => {
    const handleAccountStatusUpdated = (data: AccountStatusUpdatedEvent) => {
      try {
        const { gaccAccountId: accountId, status } = data;

        if (
          socket &&
          accountId === account?.gaccProfile?.id &&
          status === AccountStatusEnum.Inactive
        ) {
          socket.emit('keep_account_active_status');
          return;
        }

        const allChannels: ChannelData[] | undefined = queryClient.getQueryData(
          channelsKeys.lists()
        );

        const accountChannel = (allChannels || []).find(
          (channel) =>
            channel.name === accountId &&
            channel.type === TextChatChannelTypeEnum.Direct
        );

        if (accountChannel) {
          queryClient.setQueryData(
            channelsKeys.detail(accountChannel.channelId),
            { ...accountChannel, status }
          );

          queryClient.setQueryData(
            channelsKeys.lists(),
            (oldChannels?: ChannelData[]) => {
              if (!oldChannels) return oldChannels;

              const updatedChannels = oldChannels.map((channel) =>
                channel.channelId === accountChannel.channelId
                  ? {
                      ...accountChannel,
                      status,
                    }
                  : channel
              );

              return updatedChannels;
            }
          );
        }

        const allAgents: AgentData[] | undefined = queryClient.getQueryData(
          agentKeys.lists()
        );
        const agent = (allAgents || []).find((agent) => agent.id === accountId);

        if (agent) {
          queryClient.setQueryData(agentKeys.detail(agent.email), {
            ...agent,
            status,
          });

          queryClient.setQueryData(
            agentKeys.lists(),
            (oldAgents?: AgentData[]) => {
              if (!oldAgents) return oldAgents;

              const updatedAgents = oldAgents.map((oldAgent) =>
                oldAgent.id === accountId
                  ? {
                      ...agent,
                      status,
                    }
                  : oldAgent
              );

              return updatedAgents;
            }
          );
        }
      } catch (error) {
        console.error('Error updating account status:', error);
        Sentry.captureException(error);
      }
    };

    const handleImportFinished = () => {
      if (hasImportFinished) return;

      try {
        // Refetch channels
        fetchChannels(queryClient, account);
      } catch (error) {
        console.error('Error updating account status:', error);
      } finally {
        setHasImportFinished(true);
      }
    };

    if (account && socket) {
      socket.on('account_status_updated', handleAccountStatusUpdated);
      socket.on('account_import_finished', handleImportFinished);
    }

    return () => {
      if (socket) {
        socket.off('account_status_updated', handleAccountStatusUpdated);
        socket.disconnect();
        console.log('Socket disconnected');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return {
    socket,
    hasImportFinished,
  };
};
