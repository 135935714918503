import { useContext, useEffect, useState } from 'react';

import { TeamChatLoading } from '@teamchat-shared/components/LoadingScreen';
import Onboarding from '@teamchat-shared/components/Onboarding';
import { useLoading } from '@teamchat-shared/contexts/loading';
import { OAuthContext } from '@teamchat-shared/contexts/oauth';
import { useTeamChatSocket } from '@teamchat-shared/hooks/useTeamChatSocket';
import { useOnboarding } from '@teamchat-shared/hooks/useOnboarding';
import { ONBOARDING_STATUSES } from '@teamchat-shared/lib/constants';
import { useGetAgents } from '@teamchat-shared/queries/agents';
import { useInitChannels } from '@teamchat-shared/queries/channels';
import api from '@teamchat-shared/lib/api';

type Props = {
  children: React.ReactNode;
};

const AppPage = ({ children }: Props) => {
  const { token, account } = useContext(OAuthContext);
  const { state: loadingState } = useLoading();
  const { onboardingStatus } = useOnboarding();
  const [isImportFinished, setIsImportFinished] = useState(false);

  const { data: agents = [] } = useGetAgents();

  const { isFetched } = useInitChannels();
  const { socket, hasImportFinished } = useTeamChatSocket(token, account);
  const { userLoaded } = loadingState;

  const isLoading =
    !userLoaded ||
    !isFetched ||
    onboardingStatus === ONBOARDING_STATUSES.FETCHING ||
    agents.length === 0;

  useEffect(() => {
    const checkImported = async () => {
      try {
        const { finished } = await api.teamChat.getOrganizationImport();

        if (finished) {
          setIsImportFinished(finished);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching organization import:', error);

        // To avoid blocking app load, we set import as finished
        setIsImportFinished(true);
      }
    };

    if (socket) {
      checkImported();
    }
  }, [socket]);

  useEffect(() => {
    if (hasImportFinished) {
      setIsImportFinished(true);
    }
  }, [hasImportFinished]);

  if (isLoading) {
    return <TeamChatLoading key="teamchat-loading" />;
  }

  if (!isImportFinished) {
    return (
      <TeamChatLoading
        key="teamchat-loading"
        subtext="Please wait while we import your contacts..."
      />
    );
  }

  if (onboardingStatus === ONBOARDING_STATUSES.REQUIRED) {
    return <Onboarding />;
  }

  return <>{children}</>;
};

export default AppPage;
