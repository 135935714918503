import { TextChatChannelMessageData } from '@livechat/hello-utils';

import ChannelAvatar from '@teamchat-shared/design/ChannelAvatar';
import { useGetAgents } from '@teamchat-shared/queries/agents';
import {
  ChannelData,
  useGetPublicChannels,
} from '@teamchat-shared/queries/channels';
import { SidebarItem } from '@teamchat-shared/types/hello';

import { useAccount } from './useAccount';

export function useSidebarPublicChannels(): { items: SidebarItem[] } {
  const account = useAccount();

  const { data: agents = [] } = useGetAgents();
  const publicChannels = useGetPublicChannels();

  const channels = publicChannels.map((publicChannel: ChannelData) => {
    const createdAt =
      localStorage.getItem(`channel-${publicChannel.channelId}-createdAt`) ||
      null;

    let lastMessage = publicChannel.lastMessage;
    const lastMessageAuthor = agents.find(
      (agent) => agent.id === lastMessage?.base?.authorId
    );

    if (lastMessageAuthor && lastMessage && 'text' in lastMessage.extras) {
      const author =
        lastMessageAuthor.id === account?.gaccProfile?.id
          ? 'You'
          : lastMessageAuthor.name;

      lastMessage = {
        ...lastMessage,
        extras: {
          text: `${author}: ${lastMessage.extras.text}`,
        },
      };
    }

    if (!publicChannel.lastMessage && publicChannel.name !== 'general') {
      const hasCreatedByMe =
        account?.gaccProfile?.id === publicChannel?.createdBy;
      const channelAuthor = agents.find(
        (agent) => agent.id === publicChannel?.createdBy
      );
      const channelAuthorName = channelAuthor?.name || '';

      lastMessage = {
        base: {
          type: 'text',
        },
        extras: {
          text: `${
            hasCreatedByMe ? `You` : `${channelAuthorName}`
          } created this channel`,
        },
      } as TextChatChannelMessageData;
    }

    return {
      id: publicChannel.channelId,
      url: `/app/channel/${publicChannel.channelId}`,
      label: publicChannel.name,
      icon: <ChannelAvatar name={publicChannel.name} size="medium" />,
      notificationCount: publicChannel?.notifications?.unreadDirectCount || 0,
      isUnread: publicChannel?.notifications?.isUnread || false,
      lastMessage,
      isChannel: true,
      isBot: false,
      jobTitle: null,
      createdAt,
    };
  });

  return {
    items: channels,
  };
}
