/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef } from 'react';

import CustomScrollbar from '@teamchat-shared/components/CustomScrollbar';
import {
  shadowBottomStyles,
  useScrollShadows,
} from '@teamchat-shared/hooks/useScrollShadows';

const wrapperStyles = css`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const contentStyles = css`
  height: 100%;
`;

type Props = {
  children: React.ReactNode;
};

const ScrollableContainer = ({ children }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const shadowTopRef = useRef<HTMLDivElement>(null);
  const shadowBottomRef = useRef<HTMLDivElement>(null);

  useScrollShadows(contentRef, shadowTopRef, shadowBottomRef);

  return (
    <div css={wrapperStyles}>
      <div css={shadowBottomStyles} ref={shadowBottomRef}></div>

      <CustomScrollbar>
        <div css={contentStyles} ref={contentRef}>
          {children}
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default ScrollableContainer;
