/** @jsxImportSource @emotion/react */

import { Theme, css } from '@emotion/react';
import { DesignToken } from '@livechat/design-system-react-components';
import { Checkbox } from '@livechat/design-system-react-components';
import { SearchInput } from '@livechat/design-system-react-components';
import { LivechatAgentData } from '@livechat/hello-utils';
import { useState } from 'react';

import ScrollableContainer from '@teamchat-shared/components/ScrollableContainer';
import Avatar from '@teamchat-shared/design/Avatar';
import Box from '@teamchat-shared/design/Box';
import Text from '@teamchat-shared/design/Text';
import { AgentData } from '@teamchat-shared/queries/agents';

import SidebarCreateChannelStep from './SidebarCreateChannelStep';

type Props = {
  onBack: () => void;
  onClose: () => void;
  onComplete: () => void;
  agents: AgentData[];
  selectedMembers: {
    agent: AgentData;
    checked: boolean;
  }[];
  setSelectedMembers: React.Dispatch<
    React.SetStateAction<
      {
        agent: AgentData;
        checked: boolean;
      }[]
    >
  >;
  isCreating: boolean;
};

const SidebarCreateChannelStep2 = ({
  onBack,
  onClose,
  onComplete,
  selectedMembers,
  setSelectedMembers,
  isCreating,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (text: string) => {
    setSearchTerm(text);
  };

  const filteredMembersInChannel = selectedMembers
    .filter((member) =>
      member.agent.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort(
      (
        a: {
          agent: LivechatAgentData;
          checked: boolean;
        },
        b: {
          agent: LivechatAgentData;
          checked: boolean;
        }
      ) => {
        if (a.agent.name.toLowerCase() < b.agent.name.toLowerCase()) {
          return -1;
        }

        if (a.agent.name.toLowerCase() > b.agent.name.toLowerCase()) {
          return 1;
        }

        return 0;
      }
    );

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setSelectedMembers((prev) =>
      prev.map((member) => {
        if (member.agent.id === id) {
          return {
            ...member,
            checked,
          };
        }

        return member;
      })
    );
  };

  return (
    <SidebarCreateChannelStep
      title="Add members"
      completeButtonText="Create"
      isCompleteDisabled={false}
      onComplete={onComplete}
      isCreating={isCreating}
      onBack={onBack}
      onClose={onClose}
    >
      <Box
        width={{
          _: 1,
        }}
        px={{
          _: '44px',
          md: '24px',
        }}
        mb={{
          _: '30px',
          md: '40px',
        }}
      >
        <SearchInput
          placeholder="Search..."
          onChange={handleSearchChange}
          value={searchTerm}
          size="medium"
          css={(theme: Theme) => css`
            position: relative;
            z-index: 2;
            background-color: var(${DesignToken.SurfaceSecondaryDefault});

            height: 46px;
            border-radius: 14px;

            &[class*='lc-Search-module__search-input___'] {
              border: 1px solid var(${DesignToken.SurfaceSecondaryDefault});
            }
            &[class*='lc-Search-module__search-input___']:hover {
              border-color: var(${DesignToken.BorderBasicHover});
            }
            &[class*='lc-Search-module__search-input___']:focus {
              border-color: var(${DesignToken.ActionPrimaryDefault});
            }
            &[class*='lc-Search-module__search-input--focused___'],
            &[class*='lc-Search-module__search-input--focused___']:hover {
              border-color: var(${DesignToken.ActionPrimaryDefault});
            }

            @media (min-width: ${theme.breakpoints.md}) {
              height: 42px;
            }

            input {
              font-size: 18px;

              @media (min-width: ${theme.breakpoints.md}) {
                font-size: 14px;
              }
            }

            [class*='lc-Icon-module__icon--primary'] {
              color: var(${DesignToken.ContentBasicDisabled});
              > svg {
                width: 18px;
                height: 18px;
              }
            }
          `}
        />
      </Box>

      <Box
        width={{
          _: 1,
        }}
        px={{
          _: '44px',
          md: '24px',
        }}
        mb={{
          _: '16px',
          md: '60px',
        }}
        overflow="hidden"
      >
        <ScrollableContainer>
          <Box
            display="flex"
            flexDirection="column"
            overflowX="auto"
            position="relative"
            gap="12px"
            px="4px"
          >
            {filteredMembersInChannel.map((member) => (
              <Checkbox
                key={member.agent.id}
                checked={member.checked}
                onChange={() =>
                  handleCheckboxChange(member.agent.id, !member.checked)
                }
              >
                <Box display="flex" alignItems="center" gap="8px" ml="8px">
                  <Avatar
                    src={member.agent.avatar}
                    type="image"
                    text={member.agent.name}
                    size="xxsmall"
                  />

                  <Text fontSize="16px" lineHeight="1.5">
                    {member.agent.name}
                  </Text>
                </Box>
              </Checkbox>
            ))}
          </Box>
        </ScrollableContainer>
      </Box>
    </SidebarCreateChannelStep>
  );
};

export default SidebarCreateChannelStep2;
