/* eslint-disable @typescript-eslint/no-empty-function */
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import api from '@teamchat-shared/lib/api';
import { ONBOARDING_STATUSES } from '@teamchat-shared/lib/constants';
import {
  OnboardingInputType,
  OnboardingMessage,
  ResponseOnboardingMessage,
} from '@teamchat-shared/types/onboarding';

import { useLoading } from './loading';

export type OnboardingContextValue = {
  onboardingStatus: string;
  onboardingMessages: OnboardingMessage[];
  setOnboardingStatus: (status: string) => void;
};

const defaultOnboardingContextValue = {
  onboardingStatus: '',
  onboardingMessages: [],
  setOnboardingStatus: () => {},
};

export const OnboardingContext = React.createContext<OnboardingContextValue>(
  defaultOnboardingContextValue
);

type Props = {
  children: React.ReactNode;
};

const Bot = ({ children }: Props) => {
  const router = useRouter();
  const [onboardingStatus, setOnboardingStatus] = useState(
    ONBOARDING_STATUSES.IDLE
  );

  const [onboardingMessages, setOnboardingMessages] = useState<
    OnboardingMessage[]
  >([]);

  const { state: loadingState } = useLoading();

  // console.log('loadingState', loadingState)

  useEffect(() => {
    const fetchOnboardingPreview = async () => {
      if (!api.teamChat) {
        return;
      }

      setOnboardingStatus(ONBOARDING_STATUSES.FETCHING);

      try {
        const { inviteSent, completed, numberOfTeammates } =
          await api.teamChat.onboarding();

        const blockMessages: ResponseOnboardingMessage[] = [];

        if (!completed) {
          blockMessages.push({
            messages: [
              "Hi, I'm Maciej and this is TeamChat.\nThe app that lets you chat with your team.",
            ],
            awaitsResponse: false,
            input: {},
          });

          if (inviteSent || numberOfTeammates === 0) {
            blockMessages.push({
              messages: [
                'Message your teammates directly or create channels to chat in groups. ',
              ],
              awaitsResponse: true,
              input: {
                type: OnboardingInputType.Button,
                label: 'Take me to the app!',
                delay: 1,
              },
            });
          } else {
            blockMessages.push({
              messages: ['First, get your team together. '],
              awaitsResponse: true,
              input: {
                type: OnboardingInputType.Button,
                label: 'Send invitation',
                labelCompleted: 'Invitation sent',
                delay: 2000,
                onAction: async () => {
                  try {
                    await api.teamChat.sendInvite();
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log('error sending invite', error);
                  }
                },
              },
            });

            blockMessages.push({
              messages: [
                'Now, you can message your teammates directly or create channels to chat in groups.',
              ],
              awaitsResponse: true,
              input: {
                type: OnboardingInputType.Button,
                label: 'Take me to the app!',
                delay: 1,
              },
            });
          }
        }

        const flatMessages = blockMessages.reduce(
          (acc: OnboardingMessage[], block: ResponseOnboardingMessage) => {
            const blockSteps = block.messages.map((message) => {
              const onboardingMessage: OnboardingMessage = {
                type: 'text',
                message,
              };

              return onboardingMessage;
            });

            // After processing all messages in the block, check if an extra input message should be added
            if (block.awaitsResponse) {
              blockSteps.push({
                type: 'input',
                message: '',
                inputData: block.input,
              });
            }

            return [...acc, ...blockSteps];
          },
          []
        );

        setOnboardingMessages(flatMessages);

        if (flatMessages && flatMessages.length > 0) {
          setOnboardingStatus(ONBOARDING_STATUSES.REQUIRED);
          router.push(`/app/channel/general`);
        } else {
          setOnboardingStatus(ONBOARDING_STATUSES.COMPLETED);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error onboarding', error);
        setOnboardingStatus(ONBOARDING_STATUSES.ERROR);
      }
    };

    if (loadingState.userLoaded) {
      fetchOnboardingPreview();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState]);

  return (
    <OnboardingContext.Provider
      value={{
        onboardingStatus,
        onboardingMessages,
        setOnboardingStatus,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default Bot;
