import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';

import { TeamChatLoading } from '@teamchat-shared/components/LoadingScreen';
import { useChatSocket } from '@teamchat-shared/hooks/useChatSocket';

import ChatSocketContext from './chatSocket';
import { OAuthContext } from './oauth';

export const WebSocketsContext = createContext<undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

const WebSockets = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const { token } = useContext(OAuthContext);

  const {
    sendMessage,
    sendFile,
    getMessages,
    addReaction,
    removeReaction,
    listChannels,
    markMessagesAsSeen,
    isLoggedIn: chatSocketIsLoggedIn,
    isInitialized,
  } = useChatSocket(token, queryClient);

  if (!token || !chatSocketIsLoggedIn) {
    return <TeamChatLoading />;
  }

  return (
    <WebSocketsContext.Provider value={undefined}>
      <ChatSocketContext.Provider
        value={{
          sendMessage,
          sendFile,
          getMessages,
          addReaction,
          removeReaction,
          listChannels,
          markMessagesAsSeen,
          isLoggedIn: chatSocketIsLoggedIn,
          isInitialized,
        }}
      >
        {children}
      </ChatSocketContext.Provider>
    </WebSocketsContext.Provider>
  );
};

export default WebSockets;
