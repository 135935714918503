/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Send } from '@livechat/design-system-icons';
import {
  DesignToken,
  Input,
  Icon,
  Button,
} from '@livechat/design-system-react-components';
import { useState } from 'react';

import Box from '@teamchat-shared/design/Box';
import Text from '@teamchat-shared/design/Text';

type Props = {
  onSubmit: (text: string) => void;
  validator?: (value: string) => string;
};

const OnboardingInputField = ({ onSubmit, validator }: Props) => {
  const [input, setInput] = useState<string>('');
  const [validatorMessage, setValidatorMessage] = useState<string>('');
  const [fieldError, setFieldError] = useState<string>('');

  const isValid = validator ? !validatorMessage : true;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFieldError('');

    if (validator) {
      setValidatorMessage(validator(value));
    }

    setInput(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (input && isValid) {
      onSubmit(input);
    }

    if (!input) {
      setFieldError('This field is required');
    } else if (!isValid) {
      setFieldError(validatorMessage);
    }
  };

  return (
    <Box
      height="80px"
      css={css`
        > div {
          height: 54px;
          border-radius: 10px;
          &:hover,
          &:active,
          &:focus {
            border-color: #0066ff;
          }

          > input {
            font-size: 15px;
          }
        }
      `}
    >
      <Input
        error={!!fieldError}
        autoFocus
        inputSize="large"
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        value={input}
        icon={{
          source: (
            <Button
              kind="plain"
              disabled={!input}
              icon={
                <Icon
                  source={Send}
                  customColor={
                    input
                      ? `var(${DesignToken.SurfaceAccentEmphasisHighInfo})`
                      : `var(${DesignToken.ContentBasicDisabled})`
                  }
                />
              }
              onClick={() => {
                handleSubmit();
              }}
            />
          ),
          place: 'right',
        }}
      />
      {fieldError && (
        <Box mt={1}>
          <Text color={`var(${DesignToken.ColorNegativeDefault})`} size="sm">
            {fieldError}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default OnboardingInputField;
