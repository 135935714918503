/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Add } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { motion } from 'framer-motion';

import Box from '@teamchat-shared/design/Box';
import Text from '@teamchat-shared/design/Text';

type Props = {
  setIsCreateChannelMode: (isCreateChannelMode: boolean) => void;
};

const CreateChannelButton = ({ setIsCreateChannelMode }: Props) => {
  const initialWidth = 24;
  const hoverWidth = 160;

  const variants = {
    initial: {
      backgroundColor: 'transparent',
      width: `${initialWidth}px`,
      transition: { duration: 0.2 },
    },
    hover: {
      backgroundColor: '#f3f3f3',
      width: `${hoverWidth}px`,
      transition: { duration: 0.2 },
    },
  };

  return (
    <Box>
      <motion.div
        onClick={() => setIsCreateChannelMode(true)}
        layout
        initial="initial"
        whileHover="hover"
        variants={variants}
        css={css`
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 6px;
          position: relative;
          z-index: 200;
          height: 44px;
          border-radius: 14px;
          padding: 9px 10px;
        `}
      >
        <motion.div
          variants={{
            initial: {
              opacity: 0,
            },
            hover: {
              opacity: 1,
            },
          }}
          // transition={{ duration: 0.2 }}
          css={css`
            white-space: nowrap;
            overflow: hidden;
          `}
        >
          <Box display="flex">
            <Text
              bold
              css={css`
                white-space: nowrap;
                overflow: hidden;
              `}
            >
              Create channel
            </Text>
          </Box>
        </motion.div>

        <Box>
          <Icon
            size="large"
            source={Add}
            css={css`
              width: 24px;
              height: 24px;

              > svg {
                width: 24px;
                height: 24px;
              }
            `}
          />
        </Box>
      </motion.div>
    </Box>
  );

  // return (
  //   <motion.div
  //     layout
  //     initial="initial"
  //     whileHover="hover"
  //     variants={variants}
  //     onClick={() => setIsCreateChannelMode(true)}
  //     css={css`
  //       position: absolute;
  //       top: -20px;
  //       right: 0;
  //       cursor: pointer;
  //       border-radius: 14px;
  //       padding: 9px 10px;
  //     `}
  //   >
  //     <Box
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="flex-end"
  //       gap="6px"
  //     >
  //       <Icon
  //         size="large"
  //         source={Add}
  //         css={css`
  //           order: 1;
  //           width: 24px;
  //           height: 24px;

  //           > svg {
  //             width: 24px;
  //             height: 24px;
  //           }
  //         `}
  //       />

  //       <motion.div
  //         variants={{
  //           initial: {
  //             opacity: 0,
  //             left: '100px',
  //           },
  //           hover: {
  //             opacity: 1,
  //             left: 0,
  //           },
  //         }}
  //         transition={{
  //           left: { duration: 0.2 },
  //           opacity: { delay: 0.1, duration: 0.1 },
  //         }}
  //         css={css`
  //           position: relative;
  //           order: 0;
  //           white-space: nowrap;
  //           font-size: 13px;
  //           font-weight: 600;
  //         `}
  //       >
  //         Create channel
  //       </motion.div>
  //     </Box>
  //   </motion.div>
  // );
};

export default CreateChannelButton;
