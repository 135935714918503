/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DesignToken } from '@livechat/design-system-react-components';
import { motion } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';

import CustomTypeAnimation from '@teamchat-shared/components/CustomTypeAnimation';
import { LogoApp as Logo } from '@teamchat-shared/components/Logo';
import OnboardingButton from '@teamchat-shared/components/OnboardingButton';
import OnboardingInputField from '@teamchat-shared/components/OnboardingInputField';
import OnboardingLoadingBox from '@teamchat-shared/components/OnboardingLoadingBox';
import Box from '@teamchat-shared/design/Box';
import { useAccount } from '@teamchat-shared/hooks/useAccount';
import { OnboardingMessage } from '@teamchat-shared/types/onboarding';

type Step = {
  type: 'text' | 'input';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputData: any;
  message: string;
  delay?: number;
  onSubmit?: (value?: string) => void;
  onComplete?: () => void;
  userAvatarUrl: string | null;
  userName: string | null;
  id?: string;
  validator?: (value: string) => string;
};

type Props = {
  onComplete: (answers: string[]) => void;
  messages: OnboardingMessage[];
};

const transformMessages = (
  messages: OnboardingMessage[],
  answers: string[]
): Step[] => {
  return messages.map((message) => {
    const step: Step = {
      type: message.type,
      message: message.message,
      delay: 2000,
      userAvatarUrl: null,
      userName: null,
      inputData: null,
    };

    if (message.type === 'input') {
      if (message.inputData.onAction) {
        step.onSubmit = message.inputData.onAction;
      } else {
        step.onSubmit = async (value?: string) => {
          if (value) {
            answers.push(value);
          }
        };
      }
      step.delay = message.inputData.delay || 3000;
      step.inputData = message.inputData;
    }

    return step;
  });
};

const OnboardingSteps = ({ onComplete, messages }: Props) => {
  const account = useAccount();

  const onboardingRef = useRef<HTMLDivElement>(null);
  const answersRef = useRef<string[]>([]);

  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState<Step[]>(
    transformMessages(messages, answersRef.current)
  );
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }, [currentStep]);

  const handleComplete = () => {
    onComplete(answersRef.current);
  };

  const handleInputSubmit = async (submittedValue: string, index: number) => {
    const step = steps[index];

    // Replace the input step with a text step
    const updatedSteps = [...steps];
    updatedSteps[index] = {
      ...step,
      type: 'text',
      message: submittedValue,
      userAvatarUrl: account.gaccProfile.avatar,
      userName: account.gaccProfile.name,
    };

    setSteps(updatedSteps);

    if (step.onSubmit) {
      try {
        await step.onSubmit(submittedValue);
      } catch (error) {
        console.error('Error during onSubmit:', error);
      }
    }

    // Add some extra delay before updating the step
    setTimeout(() => {
      setCurrentStep((prevStep) => prevStep + 1);
    }, step.delay || 0);
  };

  const handleButtonSubmit = async (index: number) => {
    setIsButtonLoading(true);

    const step = steps[index];

    // Replace the input step with new button state
    const updatedSteps = [...steps];
    updatedSteps[index] = {
      ...step,
      inputData: {
        ...step.inputData,
        isCompleted: true,
        label: step.inputData.labelCompleted || step.inputData.label,
      },
    };

    setSteps(updatedSteps);

    if (step.onSubmit) {
      try {
        await step.onSubmit();
      } catch (error) {
        console.error('Error during onSubmit:', error);
      }
    }

    // Add some extra delay before updating the step
    setTimeout(() => {
      setIsButtonLoading(false);

      setCurrentStep((prevStep) => {
        const isLastStep = prevStep === steps.length - 1;

        // Move to the next step
        const newStep = prevStep + 1;

        if (isLastStep) {
          handleComplete();
        }

        return newStep;
      });
    }, step.delay || 0);
  };

  return (
    <Box
      ref={onboardingRef}
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      zIndex={50000}
      backgroundColor={`var(${DesignToken.SurfaceBasicDefault})`}
      p={6}
    >
      <Box
        px={6}
        py={4}
        position="absolute"
        left="0"
        top="0"
        right="0"
        zIndex={2}
      >
        <Logo />
      </Box>

      <Box
        position="absolute"
        left="0"
        top="0"
        right="0"
        bottom="50vh"
        zIndex={1}
        css={css`
          background-image: linear-gradient(
            var(${DesignToken.SurfacePrimaryDefault}),
            rgb(32 32 36 / 0%) 70%
          );
        `}
      />

      <Box display="flex" gap="20px">
        <Box
          display="flex"
          flexDirection="column"
          gap="64px"
          mb={{
            _: 0,
            md: '128px',
          }}
        >
          {steps.map((step, index) => {
            return (
              currentStep >= index && (
                <motion.div
                  key={`onboarding-step-${index}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {step.type === 'text' && (
                    <OnboardingLoadingBox
                      avatarUrl={step.userAvatarUrl}
                      name={step.userName}
                    >
                      <CustomTypeAnimation
                        sequence={[step.message]}
                        disableCursorOnComplete
                        onCompleteDelay={step.delay || 0}
                        onComplete={async () => {
                          if (!step.onSubmit) {
                            setCurrentStep((prevStep) => {
                              const isLastStep = prevStep === steps.length - 1;

                              // Move to the next step
                              const newStep = prevStep + 1;

                              if (isLastStep) {
                                handleComplete();
                              }

                              return newStep;
                            });
                          }
                        }}
                      />
                    </OnboardingLoadingBox>
                  )}

                  {step.type === 'input' &&
                    step?.inputData?.type === 'input' && (
                      <OnboardingInputField
                        onSubmit={(text: string) => {
                          handleInputSubmit(text, index);
                        }}
                        validator={step.validator}
                      />
                    )}

                  {step.type === 'input' &&
                    step?.inputData?.type === 'button' && (
                      <OnboardingButton
                        isLoading={isButtonLoading}
                        kind={
                          step.inputData.isCompleted
                            ? 'primary'
                            : 'high-contrast'
                        }
                        onSubmit={() => {
                          handleButtonSubmit(index);
                        }}
                        label={step.inputData.label}
                        isCompleted={step.inputData.isCompleted}
                      />
                    )}
                </motion.div>
              )
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingSteps;
