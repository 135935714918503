import { css } from '@emotion/react';
import { DesignToken } from '@livechat/design-system-react-components';
import { Inter } from 'next/font/google';

const inter = Inter({ subsets: ['latin'] });

export const globalStyles = (isPublicPage: boolean) => css`
  .lc-dark-theme {
    --action-primary-default: #0066ff;
    --action-primary-hover: #4379d6;
    --content-invert-primary: #fff;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    max-width: 100vw;
  }

  body {
    font-family: ${inter.style.fontFamily}, sans-serif;
    margin: 0;
    color: var(${DesignToken.ContentBasicPrimary});
    background-color: ${isPublicPage
      ? `var(${DesignToken.SurfaceBasicDefault})`
      : '#ececec'};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  // Design System overrides
  *[class^='lc-'] {
    font-family: ${inter.style.fontFamily}, sans-serif;

    > input {
      font-family: ${inter.style.fontFamily}, sans-serif;
    }
  }

  *[class*='lc-Tooltip-module__tooltip___'] {
    box-shadow: 0px 3.31469px 13.25874px 0.82867px rgba(1, 1, 1, 0.5),
      0px 0px 0px 0.82867px rgba(255, 255, 255, 0.05) inset,
      0px 0px 1.65734px 0.82867px rgba(0, 0, 0, 0.15);
  }

  *[class*='lc-Modal-module__modal-base__overlay___'] {
    background-color: rgba(0, 0, 0, 0.07);
  }

  *[class*='lc-Modal-module__modal-base__close___'] {
    min-width: 44px;
    height: 44px;
    color: var(${DesignToken.SurfaceInvertDefault});

    > span {
      width: 24px;
      height: 24px;

      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  ${isPublicPage &&
  `
     #chat-widget-container {
          bottom: 0 !important;
          right: 0 !important;
        }
    `}
`;
