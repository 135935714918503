import {
  LivechatChatData,
  LivechatRoutingStatusEnum,
  LivechatSneakPeekData,
  LivechatUserTypeEnum,
} from '@livechat/hello-utils';
import { formatDistanceToNow, parseISO, format } from 'date-fns';

import { NOTIFICATIONS_TYPE } from '@teamchat-shared/lib/constants';
import { SidebarItem } from '@teamchat-shared/types/hello';

export const getItemsFromChats = (
  chats: LivechatChatData[],
  countNotifications: (type: string, id: string, isDirect: boolean) => number,
  sneakPeeks?: LivechatSneakPeekData[]
) => {
  return chats.map((chat) => {
    const { id, users = [] } = chat;
    const customer = users.find(
      (user) => user.type === LivechatUserTypeEnum.Customer
    );

    const name = customer?.name || 'Customer';
    const sneakPeek = sneakPeeks?.find(
      (sneakPeek: LivechatSneakPeekData) => sneakPeek.chatId === id
    );

    return {
      url: `/app/chat/${id}`,
      label: name,
      badgeCount: countNotifications(NOTIFICATIONS_TYPE.CHAT, id, false),
      sneakPeek: sneakPeek?.text || '',
      avatarStatus: customer?.present
        ? LivechatRoutingStatusEnum.AcceptingChats
        : LivechatRoutingStatusEnum.Offline,
    };
  });
};

export const formatRelativeDate = (dateStr: string) => {
  const date: Date = parseISO(dateStr);
  const now: Date = new Date();

  const diffInSeconds: number = (now.getTime() - date.getTime()) / 1000;

  // For "now" (within 60 seconds)
  if (diffInSeconds < 60) return 'now';

  // For times within the last hour
  if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} mins`;

  if (diffInSeconds >= 3600 && diffInSeconds < 7200) return '1 hour';

  // For times within the first 23 hours
  if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours`;

  // For "yesterday"
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);
  const isYesterday =
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear();

  if (isYesterday) return 'yesterday';

  // For dates older than 2 days, show the date
  const twoDaysAgo = new Date(now);
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
  if (date < twoDaysAgo) {
    // Customize this format as you like
    return format(date, 'MMM dd, yyyy');
  }

  // For all other cases, use formatDistanceToNow
  return formatDistanceToNow(date, { addSuffix: true });
};

export const sortItems = (a: SidebarItem, b: SidebarItem): number => {
  const getLastMessageDate = (item: SidebarItem) =>
    item.lastMessage
      ? new Date(item.lastMessage.base.createdAt).getTime()
      : null;

  const getCreatedAtDate = (item: SidebarItem) =>
    item.createdAt ? new Date(item.createdAt).getTime() : null;

  const dateA = getLastMessageDate(a) || getCreatedAtDate(a);
  const dateB = getLastMessageDate(b) || getCreatedAtDate(b);

  if (dateA === null && dateB === null) {
    return 0;
  } else if (dateA === null) {
    return 1;
  } else if (dateB === null) {
    return -1;
  }

  return dateB - dateA;
};
