import OnboardingSteps from '@teamchat-shared/components/OnboardingSteps';
import { useOnboarding } from '@teamchat-shared/hooks/useOnboarding';
import api from '@teamchat-shared/lib/api';
import { ONBOARDING_STATUSES } from '@teamchat-shared/lib/constants';

const Onboarding = () => {
  const { setOnboardingStatus, onboardingMessages } = useOnboarding();

  const handleOnboardingCompleted = async () => {
    try {
      await api.teamChat.completeOnboarding();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <OnboardingSteps
      messages={onboardingMessages}
      onComplete={async () => {
        await handleOnboardingCompleted();
        setOnboardingStatus(ONBOARDING_STATUSES.COMPLETED);
      }}
    />
  );
};

export default Onboarding;
