import { FiletypeTxt } from '@livechat/design-system-icons';

import ChannelAvatar from '@teamchat-shared/design/ChannelAvatar';
import { AgentData, useGetAgents } from '@teamchat-shared/queries/agents';
import { useGetBots } from '@teamchat-shared/queries/bots';
import {
  ChannelData,
  useGetDirectChannels,
} from '@teamchat-shared/queries/channels';
import { SidebarItem } from '@teamchat-shared/types/hello';

import { useAccount } from './useAccount';

const createChannel = (
  item: AgentData,
  myAccountId: string,
  directChannels: ChannelData[],
  isBot: boolean
) => {
  const directChannel = directChannels.find(
    (directChannel) => directChannel?.name === item.id
  );
  let lastMessage = directChannel?.lastMessage || null;

  const teamId = directChannel?.channelId || item.id;

  const { id, name, avatar, jobTitle } = item;

  if (myAccountId === id && !isBot) {
    return {
      id: teamId,
      url: `/app/team/${teamId}`,
      label: 'Personal notes',
      icon: (
        <ChannelAvatar name="Personal notes" icon={FiletypeTxt} size="medium" />
      ),
      notificationCount: directChannel?.notifications?.unreadDirectCount || 0,
      isUnread: directChannel?.notifications?.isUnread || false,
      lastMessage,
      isChannel: false,
      isBot: false,
      createdAt: null,
      jobTitle: null,
    };
  }

  if (lastMessage && 'text' in lastMessage.extras) {
    lastMessage = {
      ...lastMessage,
      extras: {
        text:
          myAccountId === lastMessage.base.authorId
            ? `You: ${lastMessage.extras.text}`
            : lastMessage.extras.text,
      },
    };
  }

  return {
    id: teamId,
    url: `/app/team/${teamId}`,
    label: name,
    avatar,
    notificationCount: directChannel?.notifications?.unreadDirectCount || 0,
    isUnread: directChannel?.notifications?.isUnread || false,
    lastMessage,
    isChannel: false,
    isBot,
    createdAt: null,
    status: directChannel?.status || item?.status || 'inactive',
    jobTitle,
    disabled: item.disabled,
  };
};

export function useSidebarDirectChannels(): { items: SidebarItem[] } {
  const account = useAccount();

  const { data: agents = [] } = useGetAgents();
  const { data: bots = [] } = useGetBots();
  const directChannels = useGetDirectChannels();

  const agentsChannels = agents.map((agent) =>
    createChannel(agent, account?.gaccProfile?.id, directChannels, false)
  );

  const botsChannels = bots.map((bot) =>
    createChannel(bot, account?.gaccProfile?.id, directChannels, true)
  );

  const channels = [...agentsChannels, ...botsChannels];

  return {
    items: channels,
  };
}
