import {
  useGetMyProducts,
  useGetSubscriptions,
} from '@teamchat-shared/queries/products';

export const useProductData = () => {
  const { data: installedProducts = [] } = useGetMyProducts();

  const { data: subscriptions = [] } = useGetSubscriptions();

  return {
    installedProducts,
    subscriptions,
  };
};
