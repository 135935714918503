/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { CannedResponse } from '@livechat/design-system-icons';
import { DesignToken, Icon } from '@livechat/design-system-react-components';
import { useState } from 'react';

import Box from '@teamchat-shared/design/Box';
import ChannelAvatar from '@teamchat-shared/design/ChannelAvatar';
import Input from '@teamchat-shared/design/Input';
import Text from '@teamchat-shared/design/Text';

import SidebarCreateChannelStep from './SidebarCreateChannelStep';

type Props = {
  channelsIds: string[];
  channelName: string;
  onClose: () => void;
  onComplete: (channelName: string) => void;
};

const SidebarCreateChannelStep1 = ({
  channelsIds,
  channelName,
  onComplete,
  onClose,
}: Props) => {
  const [name, setName] = useState(channelName);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // For now API supports only lowercase alphanumeric characters
    const filteredInput = e.target.value
      .replace(/[^a-z0-9-_]/gi, '')
      .toLowerCase();

    setName(filteredInput);
    setErrorMessage('');

    // Check if the channel name already exists
    if (channelsIds.includes(filteredInput)) {
      setErrorMessage('Channel with this name already exists.');
    }
  };

  const handleCompleteStep = () => {
    onComplete(name);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCompleteStep();
    }
  };

  return (
    <SidebarCreateChannelStep
      title="Create channel"
      completeButtonText="Add members"
      isCompleteDisabled={!name.length || !!errorMessage}
      onComplete={handleCompleteStep}
      onClose={onClose}
    >
      <Box
        mb={{
          _: '30px',
          md: '40px',
        }}
      >
        <ChannelAvatar
          name={channelName}
          size="large"
          customColor={{
            background: `var(${DesignToken.SurfaceSecondaryDefault})`,
            icon: `var(${DesignToken.ContentBasicPrimary})`,
          }}
        />
      </Box>

      <Box
        width={{
          _: 1,
          md: 'auto',
        }}
        px={{
          _: '44px',
          md: 0,
        }}
        mb={{
          _: '16px',
          md: '24px',
        }}
      >
        <Input
          inputSize="large"
          icon={{
            source: <Icon source={CannedResponse} />,
            place: 'left',
          }}
          placeholder="Name channel"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={name}
          autoFocus
          backgroundColor={`var(${DesignToken.SurfaceSecondaryDefault})`}
          error={!!errorMessage}
          css={css`
            &[class*='lc-Input-module__input___'] {
              border: 1px solid var(${DesignToken.SurfaceSecondaryDefault});
            }
            &[class*='lc-Input-module__input___']:hover {
              border-color: var(${DesignToken.BorderBasicHover});
            }
            &[class*='lc-Input-module__input___']:focus {
              border-color: var(${DesignToken.ActionPrimaryDefault});
            }
            &[class*='lc-Input-module__input--focused___'],
            &[class*='lc-Input-module__input--focused___']:hover {
              border-color: var(${DesignToken.ActionPrimaryDefault});
            }

            > input {
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              opacity: ${name.length ? 1 : 0.5};
            }
          `}
        />
        {errorMessage && (
          <Box mt="8px">
            <Text size="xs" color={`var(${DesignToken.ContentBasicNegative})`}>
              {errorMessage}
            </Text>
          </Box>
        )}
      </Box>
    </SidebarCreateChannelStep>
  );
};

export default SidebarCreateChannelStep1;
