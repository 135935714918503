/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DesignToken } from '@livechat/design-system-react-components';

import Avatar from '@teamchat-shared/design/Avatar';
import Box from '@teamchat-shared/design/Box';
import Heading from '@teamchat-shared/design/Heading';

type OnboardingLoadingBoxProps = {
  children: React.ReactNode;
  avatarUrl: string | null;
  name: string | null;
};

const OnboardingLoadingBox = ({
  children,
  avatarUrl,
  name,
}: OnboardingLoadingBoxProps) => {
  return (
    <Box position="relative" height="auto">
      <Box display="flex" flexDirection="column">
        <Box display="flex" gap="14px" alignItems="center">
          {avatarUrl || name ? (
            <Avatar
              src={avatarUrl}
              text={name}
              type={avatarUrl ? 'image' : 'text'}
              size="medium"
              css={css`
                width: 34px;
                height: 34px;
              `}
            />
          ) : (
            <Avatar
              src="/maciej.png"
              text="Maciej logo"
              type={'image'}
              size="medium"
              css={css`
                width: 34px;
                height: 34px;
              `}
            />
          )}

          <Heading
            size="lg"
            fontWeight="400"
            lineHeight="1.25"
            customColor={`var(${DesignToken.ContentBasicDisabled})`}
          >
            {name ? (
              name
            ) : (
              <span>
                Maciej{' '}
                <span
                  css={css`
                    color: rgba(141, 141, 149, 0.6);
                  `}
                >
                  I Designer
                </span>
              </span>
            )}
          </Heading>
        </Box>

        <Box
          width={{ _: '100%', md: '880px' }}
          maxWidth={{
            _: '100%',
            md: '880px',
          }}
          display="flex"
        >
          <Box minWidth="48px" />
          <Heading
            size="xl"
            fontSize={{
              _: '20px',
              md: '40px',
            }}
            fontWeight="500"
            lineHeight="1.25"
            color={`var(${DesignToken.ContentBasicPrimary})`}
          >
            {children}
          </Heading>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingLoadingBox;
