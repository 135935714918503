/** @jsxImportSource @emotion/react */
import { Theme, css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useMedia } from 'react-use';

import { useSidebarContext } from '@teamchat-shared/contexts/sidebar';
import Box from '@teamchat-shared/design/Box';
import { useSidebar } from '@teamchat-shared/hooks/useSidebar';
import { useGetChannels } from '@teamchat-shared/queries/channels';
import { breakpoints } from '@teamchat-shared/styles/theme';

import Sidebar from './Sidebar';
import { mainStyles } from './styles';

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const { isVisible, setIsVisible } = useSidebarContext();
  const sidebarData = useSidebar();
  const isMobile = useMedia(`(max-width: ${breakpoints.md})`);

  useGetChannels();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={{
        _: 'initial',
        md: 'center',
      }}
      maxWidth={{
        _: '100%',
      }}
      width="100%"
      backgroundColor="#fff"
      height={{
        _: '100dvh',
      }}
      boxShadow={{
        _: 'none',
      }}
      m={{
        _: '0 auto',
      }}
      borderRadius={{
        _: 0,
      }}
      css={(theme: Theme) => css`
        @media (min-width: ${theme.breakpoints.xxl}) and (max-height: 600px) {
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 0;
          height: 100vh;
        }
      `}
    >
      <Box display="flex" height="100%" width="100%">
        <Sidebar
          isVisible={isVisible}
          setSidebarVisible={isMobile ? setIsVisible : undefined}
          allItems={sidebarData.allItems}
          isMobile={isMobile}
        />

        <motion.main
          css={mainStyles}
          initial={isMobile ? { x: '100%' } : {}}
          animate={isMobile ? { x: isVisible ? '0' : '-100%' } : {}}
          transition={isMobile ? { duration: 0.2 } : {}}
        >
          {children}
        </motion.main>
      </Box>
    </Box>
  );
}
