/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

type Props = {
  sequence: string[];
  disableCursorOnComplete?: boolean;
  onComplete?: () => void;
  onCompleteDelay?: number;
};

const cursorClassName = 'custom-type-animation-cursor';

const CustomTypeAnimation = ({
  sequence,
  disableCursorOnComplete,
  onComplete,
  onCompleteDelay = 0,
}: Props) => {
  const [isCompleted, setIsCompleted] = useState(false);

  return (
    <div
      css={css`
        .${cursorClassName}::after {
          content: '${disableCursorOnComplete && isCompleted ? '' : '|'}';
          animation: cursor 1.1s infinite step-start;
        }
        @keyframes cursor {
          50% {
            opacity: 0;
          }
        }
      `}
    >
      <TypeAnimation
        style={{ whiteSpace: 'pre-line', display: 'block' }}
        sequence={[
          ...sequence,
          (el) => {
            if (el) {
              // Workaround to hide cursor, there is no api for that ye
              setTimeout(() => {
                setIsCompleted(true);

                if (onComplete) {
                  onComplete();
                }
              }, onCompleteDelay);
            }
          },
        ]}
        wrapper="div"
        speed={70}
        cursor={true}
        className={cursorClassName}
      />
    </div>
  );
};

export default CustomTypeAnimation;
