import { sortItems } from '@teamchat-shared/lib/sidebar';
import { useGetChannels } from '@teamchat-shared/queries/channels';

import { useSidebarDirectChannels } from './useSidebarDirectChannels';
import { useSidebarPublicChannels } from './useSidebarPublicChannels';

export function useSidebar() {
  const { items: publicChannels } = useSidebarPublicChannels();
  const { items: directChannels } = useSidebarDirectChannels();

  useGetChannels();

  const allItems = [...publicChannels, ...directChannels].sort(sortItems);

  return {
    allItems,
  };
}
