/** @jsxImportSource @emotion/react */

import { useRouter } from 'next/navigation';
import { useState } from 'react';

import { useAmplitudeContext } from '@teamchat-shared/contexts/amplitude';
import { useToasts } from '@teamchat-shared/contexts/toasts';
import { useAccount } from '@teamchat-shared/hooks/useAccount';
import api from '@teamchat-shared/lib/api';
import { AMPLITUDE_EVENT_TYPES } from '@teamchat-shared/lib/constants';
import { useGetAgents } from '@teamchat-shared/queries/agents';

import SidebarCreateChannelStep1 from './SidebarCreateChannelStep1';
import SidebarCreateChannelStep2 from './SidebarCreateChannelStep2';

type Props = {
  channelsIds: string[];
  onClose: () => void;
};

const SidebarCreateChannel = ({ channelsIds, onClose }: Props) => {
  const account = useAccount();
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const [isCreating, setIsCreating] = useState(false);
  const router = useRouter();

  const { notifyError } = useToasts();
  const { data: agents = [] } = useGetAgents();

  const teamMembers = agents.filter(
    (agent) => agent.id !== account.gaccProfile.id
  );
  const [selectedMembers, setSelectedMembers] = useState(
    teamMembers.map((agent) => {
      return {
        agent,
        checked: false,
      };
    })
  );
  const [step, setStep] = useState(1);
  const [channelName, setChannelName] = useState('');

  const handleStep1Complete = (channelName: string) => {
    setChannelName(channelName);
    setStep(2);
  };

  const handleStep2Complete = () => {
    handleChannelCreate();
  };

  const handleChannelCreate = async () => {
    setIsCreating(true);
    try {
      const membersIds = selectedMembers
        .filter((member) => member.checked)
        .map((member) => member.agent.id);

      // Add my id to the channel as a creator
      membersIds.push(account.gaccProfile.id);

      const response = await api.teamChat.createChannel(
        'public',
        membersIds,
        channelName
      );

      trackAmplitudeEvent(AMPLITUDE_EVENT_TYPES.CHANNEL_CREATED, {});
      router.push(`/app/channel/${response.channelId}`);
      onClose();
    } catch (error) {
      notifyError('An error occurred while creating a channel.');
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <>
      {step === 1 && (
        <SidebarCreateChannelStep1
          channelsIds={channelsIds}
          channelName={channelName}
          onClose={onClose}
          onComplete={handleStep1Complete}
        />
      )}
      {step === 2 && (
        <SidebarCreateChannelStep2
          onClose={onClose}
          onComplete={handleStep2Complete}
          agents={agents}
          onBack={() => setStep(1)}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          isCreating={isCreating}
        />
      )}
    </>
  );
};

export default SidebarCreateChannel;
