import { QueryClient } from '@tanstack/react-query';
import React from 'react';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      // We set these to infinity because most of our data is coming from websockets
      // If you want to change it set it in the specific query
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

export const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);
