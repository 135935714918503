/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { DesignToken } from '@livechat/design-system-react-components';
import { motion } from 'framer-motion';
import localFont from 'next/font/local';
import Image from 'next/image';

import Box from '@teamchat-shared/design/Box';
import logoImg from 'public/logo.svg';

const colfax = localFont({
  src: [
    {
      path: '../../fonts/ColfaxWebRegular.woff2',
      weight: 'normal',
      style: 'normal',
    },
    {
      path: '../../fonts/ColfaxWebRegularItalic.woff2',
      weight: 'normal',
      style: 'italic',
    },
    {
      path: '../../fonts/ColfaxWebBold.woff2',
      weight: 'bold',
      style: 'normal',
    },
    {
      path: '../../fonts/ColfaxWebMedium.woff2',
      weight: '500',
      style: 'normal',
    },
  ],
});

export const LoadingScreen = () => {
  return (
    <Box
      className={colfax.className}
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      zIndex={10000}
      backgroundColor={`var(${DesignToken.SurfaceBasicDefault})`}
      p={6}
    >
      <Box display="flex" gap="20px">
        <Box display="flex" flexDirection="column">
          <Box width={{ _: '100%', md: '420px' }}>
            <h3
              className={colfax.className}
              css={(theme: Theme) => css`
                color: var(${DesignToken.ContentBasicPrimary});
                font-size: 50px;
                font-weight: 500;
                line-height: 22px;
                text-align: center;
                letter-spacing: -0.04em;

                ${theme.mq('md')} {
                  font-size: 100px;
                  line-height: 28px;
                }
              `}
            >
              TeamChat
              <span
                css={css`
                  animation: cursor 0.8s infinite step-start;
                  @keyframes cursor {
                    50% {
                      opacity: 0;
                    }
                  }
                `}
              >
                |
              </span>
            </h3>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type TeamChatLoadingProps = {
  subtext?: string;
};

export const TeamChatLoading = ({ subtext }: TeamChatLoadingProps) => {
  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      zIndex={10000}
      backgroundColor={`var(${DesignToken.SurfaceBasicDefault})`}
      p={6}
    >
      <Box
        display="flex"
        gap={{
          _: '12px',
          md: '24px',
        }}
        alignItems="center"
      >
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: 180 }}
          transition={{
            type: 'spring',
            damping: 10,
            mass: 0.75,
            stiffness: 100,
            repeat: Infinity,
            repeatDelay: 1,
          }}
          css={(theme: Theme) => css`
            width: 100%;
            height: 60px;

            ${theme.mq('md')} {
              height: 80px;
            }
          `}
        >
          <Image
            src={logoImg}
            alt="Logo"
            width={100}
            height={100}
            priority
            css={(theme: Theme) => css`
              width: 60px;
              height: 60px;

              ${theme.mq('md')} {
                width: 80px;
                height: 80px;
              }
            `}
          />
        </motion.div>

        <div
          className={colfax.className}
          css={(theme: Theme) => css`
            color: var(${DesignToken.ContentBasicPrimary});
            font-weight: 500;
            letter-spacing: -0.04em;
            font-size: 56px;
            height: 60px;

            ${theme.mq('md')} {
              font-size: 70px;
              height: 70px;
            }
          `}
        >
          TeamChat
        </div>
      </Box>

      <Box mt="24px" minHeight="20px">
        {subtext}
      </Box>
    </Box>
  );
};
