import { useQuery } from '@tanstack/react-query';

import api from '@teamchat-shared/lib/api';

const useMyProductsQuery = () => {
  return useQuery({
    queryKey: ['my-products'],
    queryFn: () => api.accounts.getMyProducts(),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });
};

export const useGetMyProducts = () => {
  return useMyProductsQuery();
};

const useGetSubscriptionsQuery = () => {
  return useQuery({
    queryKey: ['subscriptions'],
    queryFn: () => api.billing.getSubscriptions(),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });
};

export const useGetSubscriptions = () => {
  return useGetSubscriptionsQuery();
};
