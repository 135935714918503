/** @jsxImportSource @emotion/react */

import { useEffect, useRef } from 'react';

import ScrollableContainer from '@teamchat-shared/components/ScrollableContainer';
import Box from '@teamchat-shared/design/Box';
import { SidebarItem } from '@teamchat-shared/types/hello';

import SidebarItemComponent from './SidebarItem';

type Props = {
  allItems: SidebarItem[];
  setSidebarVisible?: (isVisible: boolean) => void;
  sidebarContainerRef: React.RefObject<HTMLDivElement>;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

const SidebarList = ({
  setSidebarVisible,
  allItems,
  sidebarContainerRef,
  selectedIndex,
  setSelectedIndex,
}: Props) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const selectedElement = itemRefs.current[selectedIndex];

    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  return (
    <Box
      ref={sidebarContainerRef}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      flexGrow={1}
      pl={22}
      pt={{
        _: 0,
        md: '4px',
      }}
      pb={{
        _: 0,
        md: '90px',
      }}
      overflowY="hidden"
      gap={{
        _: '12px',
        md: '4px',
      }}
      height="100%"
    >
      <ScrollableContainer>
        <Box
          ref={scrollableRef}
          display="flex"
          flexDirection="column"
          gap={{
            _: '12px',
            md: '4px',
          }}
          pr="22px"
        >
          {allItems.map((item, index) => (
            <SidebarItemComponent
              key={item.url}
              id={item.id}
              url={item.url}
              icon={item.icon}
              label={item.label}
              avatar={item.avatar}
              lastMessage={item.lastMessage}
              notificationCount={item.notificationCount}
              isUnread={item.isUnread}
              setSidebarVisible={setSidebarVisible}
              isSelected={index === selectedIndex}
              setSelectedIndex={setSelectedIndex}
              ref={(el) => (itemRefs.current[index] = el)}
              status={item?.status}
              isBot={item.isBot}
              jobTitle={item.jobTitle}
              disabled={item.disabled}
            />
          ))}
        </Box>
      </ScrollableContainer>
    </Box>
  );
};

export default SidebarList;
