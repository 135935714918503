/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Close } from '@livechat/design-system-icons';
import { ArrowBack } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { Heading, Button } from '@livechat/design-system-react-components';

import Box from '@teamchat-shared/design/Box';

type SidebarCreateChannelStepProps = {
  title: string;
  onBack?: () => void;
  onClose?: () => void;
  onComplete: () => void;
  children: React.ReactNode;
  completeButtonText: string;
  isCompleteDisabled: boolean;
  isCreating?: boolean;
};

const SidebarCreateChannelStep = ({
  title,
  onBack,
  onClose,
  onComplete,
  children,
  completeButtonText,
  isCompleteDisabled,
  isCreating,
}: SidebarCreateChannelStepProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt={{
        _: '56px',
        md: '56px',
      }}
      height="100%"
    >
      <Box
        width={{
          _: 1,
          md: 'calc(100% - 48px)',
        }}
        px={{
          _: '44px',
          md: '24px',
        }}
        display="flex"
        alignItems="center"
        justifyContent={{
          _: 'space-between',
          md: 'center',
        }}
        position="relative"
        mb={{
          _: '30px',
          md: '56px',
        }}
      >
        <Box
          display={{
            _: 'none',
            md: 'block',
          }}
        >
          {onBack && (
            <Button
              css={css`
                position: absolute;
                left: 0;
                top: -4px;
              `}
              kind="text"
              icon={
                <Icon
                  size="large"
                  source={ArrowBack}
                  css={css`
                    width: 24px;
                    height: 24px;

                    > svg {
                      width: 24px;
                      height: 24px;
                    }
                  `}
                />
              }
              onClick={onBack}
            />
          )}
        </Box>
        <Heading size="sm">{title}</Heading>

        <Box
          display={{
            _: 'block',
            md: 'none',
          }}
        >
          {onClose && (
            <Button
              kind="text"
              icon={
                <Icon
                  size="large"
                  source={Close}
                  css={css`
                    width: 24px;
                    height: 24px;

                    > svg {
                      width: 24px;
                      height: 24px;
                    }
                  `}
                />
              }
              onClick={onClose}
            />
          )}
        </Box>
      </Box>

      {children}

      <Box
        width={{
          _: 1,
          md: 1,
        }}
        px={{
          _: '44px',
          md: '36px',
        }}
        pb={{
          _: 36,
          md: 36,
        }}
        flexGrow={2}
        display="flex"
        flexDirection="column-reverse"
        alignItems="flex-end"
        zIndex={2}
      >
        <Button
          kind="primary"
          size="large"
          onClick={onComplete}
          disabled={isCompleteDisabled}
          loading={isCreating}
        >
          {completeButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default SidebarCreateChannelStep;
