'use client';

import '@livechat/design-system-react-components/dist/style.css';
import '@teamchat-shared/styles/github-markdown.css';

import { ThemeProvider, Global } from '@emotion/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import AppPage from '@teamchat-shared/components/AppPage';
import AppLayout from '@teamchat-shared/components/Layout';
import PiwikScript from '@teamchat-shared/components/PiwikScript';
import AmplitudeProvider from '@teamchat-shared/contexts/amplitude';
import { BrowserStatusProvider } from '@teamchat-shared/contexts/browserStatus';
import { LoadingProvider } from '@teamchat-shared/contexts/loading';
import Notifications from '@teamchat-shared/contexts/notifications';
import OAuth from '@teamchat-shared/contexts/oauth';
import Onboarding from '@teamchat-shared/contexts/onboarding';
import { SeoProvider } from '@teamchat-shared/contexts/seo';
import { SidebarProvider } from '@teamchat-shared/contexts/sidebar';
import { ToastsProvider } from '@teamchat-shared/contexts/toasts';
import WebSockets from '@teamchat-shared/contexts/webSockets';
import { config } from '@teamchat-shared/lib/config';
import { PUBLIC_ROUTES } from '@teamchat-shared/lib/constants';
import { globalStyles } from '@teamchat-shared/styles/global';
import { theme } from '@teamchat-shared/styles/theme';
import {
  queryClient,
  ReactQueryDevtoolsProduction,
} from '@teamchat-shared/queries';

type AppProps = {
  children: React.ReactNode;
};

export default function ClientLayout({ children }: AppProps) {
  const pathname = usePathname();
  const [showDevtools, setShowDevtools] = useState(false);

  const isPublicPage = pathname && PUBLIC_ROUTES.includes(pathname);

  let Layout = (props: { children: React.ReactNode }) => <>{props.children}</>;

  if (!isPublicPage) {
    Layout = AppLayout;
  }

  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (window && window?.LC_API) {
      // For app, hide the chat window
      if (!isPublicPage) {
        window?.LC_API.hide_chat_window();
        window?.LiveChatWidget?.call('destroy');
      }
    }
  }, [isPublicPage]);

  // forcing dark mode for now
  useEffect(() => {
    document.body.className = 'lc-light-theme';
  }, [isPublicPage]);

  React.useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  const page = <Layout>{children}</Layout>;

  return (
    <>
      <LoadingProvider>
        <ThemeProvider theme={theme}>
          <Global styles={globalStyles(!!isPublicPage)} />
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen />
            {showDevtools && (
              <React.Suspense fallback={null}>
                <ReactQueryDevtoolsProduction />
              </React.Suspense>
            )}

            {isPublicPage && (
              <Script strategy="lazyOnload" id="livechat-widget">
                {`
          window.__lc = window.__lc || {};
          window.__lc.license = ${config.licenseId};
          ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="${config.lcCDNURL}/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
        `}
              </Script>
            )}

            {config.hotjarId && (
              <Script id="hotjar-script">
                {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${config.hotjarId},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
              </Script>
            )}

            {isPublicPage ? (
              page
            ) : (
              <BrowserStatusProvider>
                <AmplitudeProvider>
                  <SeoProvider>
                    <Notifications>
                      <ToastsProvider>
                        <SidebarProvider>
                          <OAuth>
                            <WebSockets>
                              <Onboarding>
                                <AppPage>{page}</AppPage>
                              </Onboarding>
                            </WebSockets>
                          </OAuth>
                        </SidebarProvider>
                      </ToastsProvider>
                    </Notifications>
                  </SeoProvider>
                </AmplitudeProvider>
              </BrowserStatusProvider>
            )}
          </QueryClientProvider>
        </ThemeProvider>
      </LoadingProvider>
      <PiwikScript />
    </>
  );
}
