/** @jsxImportSource @emotion/react */
import { Theme, css } from '@emotion/react';
import {
  DesignToken,
  Badge,
  Tag,
} from '@livechat/design-system-react-components';
import { TextChatChannelMessageData } from '@livechat/hello-utils';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import Avatar from '@teamchat-shared/design/Avatar';
import Box from '@teamchat-shared/design/Box';
import { getAgentsLookup } from '@teamchat-shared/lib/agent';
import { MAX_NOTIFICATIONS } from '@teamchat-shared/lib/constants';
import { getDraft, parseDraftText } from '@teamchat-shared/lib/draft';
import { parseLastMessage } from '@teamchat-shared/lib/message';
import { formatRelativeDate } from '@teamchat-shared/lib/sidebar';
import aiImg from 'public/ai.svg';
import { useGetAgents } from '@teamchat-shared/queries/agents';

export type Props = {
  id: string;
  url: string;
  icon?: React.ReactNode;
  label: string;
  avatar?: string;
  notificationCount?: number;
  isUnread?: boolean;
  lastMessage: TextChatChannelMessageData | null;
  isChannel?: boolean;
  isBot: boolean;
  jobTitle: string | null;
  setSidebarVisible?: (isVisible: boolean) => void;
  isSelected?: boolean;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  status?: string;
  disabled?: boolean;
};

const SidebarItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      url,
      icon,
      label,
      avatar,
      lastMessage,
      notificationCount = 0,
      isUnread,
      setSidebarVisible,
      isSelected,
      setSelectedIndex,
      status,
      isBot,
      jobTitle,
      disabled,
    },
    ref
  ) => {
    const [draft, setDraft] = useState(getDraft(id));
    const pathname = usePathname();
    const router = useRouter();
    const { data: agents = [] } = useGetAgents();

    const [lastMessageSentDate, setLastMessageSentDate] = useState('');

    const agentsLookup = useMemo(() => getAgentsLookup(agents), [agents]);

    useEffect(() => {
      // Interval to update createdAt every minute for relative time display
      const intervalId = setInterval(() => {
        if (lastMessage?.base?.createdAt) {
          setLastMessageSentDate(
            formatRelativeDate(lastMessage?.base?.createdAt)
          );
        }
      }, 60000);

      // Update createdAt immediately when lastMessage changes
      if (lastMessage?.base?.createdAt) {
        setLastMessageSentDate(
          formatRelativeDate(lastMessage?.base?.createdAt)
        );
      }

      // Clean up on component unmount or when lastMessage changes
      return () => clearInterval(intervalId);
    }, [lastMessage?.base?.createdAt]);

    useEffect(() => {
      function checkDraft() {
        const item = getDraft(id);

        if (item) {
          setDraft(item);
        } else {
          setDraft('');
        }
      }

      window.addEventListener(`storage_${id}`, checkDraft);

      return () => {
        window.removeEventListener(`storage_${id}`, checkDraft);
      };
    }, [id]);

    const lastMessageText = lastMessage
      ? parseLastMessage(lastMessage, agentsLookup)
      : '';

    const isActiveItem = pathname === url;

    useEffect(() => {
      if (isActiveItem) {
        const activeElement = document.getElementById(`item-${url}`);

        if (activeElement) {
          activeElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        id={`item-${url}`}
        key={`item-${url}`}
        ref={ref}
        css={css`
          cursor: pointer;
          padding: 18px 12px 18px 16px;
          border-radius: 14px;
          transition-duration: 0.15s;
          transition-property: opacity, border, color, background-color,
            box-shadow;
          transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);

          ${isActiveItem
            ? `background-color: var(${DesignToken.SurfacePrimaryHover});`
            : `
            &:hover {
              ${
                isSelected
                  ? ''
                  : `background-color: var(${DesignToken.SurfacePrimaryHover});`
              }
            }
        `}

          ${isSelected ? `background-color: rgb(0 0 0 / 4%);` : ''}
        `}
        onMouseEnter={() => {
          router.prefetch(url);
          setSelectedIndex(-1);
        }}
        onClick={() => {
          router.push(url);

          if (setSidebarVisible) {
            setTimeout(() => {
              setSidebarVisible(false);
            }, 300);
          }
        }}
      >
        <Box display="flex" gap={{ _: '10px', md: '8px' }}>
          <Box>
            {icon ? (
              icon
            ) : (
              <Avatar
                src={avatar}
                text={label}
                size="large"
                type={avatar ? 'image' : 'text'}
                css={(theme: Theme) => css`
                  width: 48px;
                  height: 48px;

                  @media (min-width: ${theme.breakpoints.md}) {
                    width: 42px;
                    height: 42px;
                  }
                `}
                status={disabled ? undefined : status}
              />
            )}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            overflow="hidden"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={lastMessageSentDate ? 'flex-start' : 'center'}
              height="100%"
            >
              <Box display="flex" alignItems="flex-start" gap="6px">
                <Box
                  pr="4px"
                  css={(theme: Theme) => css`
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                    @media (min-width: ${theme.breakpoints.md}) {
                      font-size: 16px;
                      line-height: 19px;
                    }

                    ${disabled
                      ? `color: var(${DesignToken.ContentBasicDisabled});`
                      : ''}
                  `}
                >
                  {label}
                </Box>

                {isBot && (
                  <Box display="flex" alignItems="center">
                    <Image
                      src={aiImg}
                      alt="AI logo"
                      width={24}
                      height={18}
                      priority
                    />
                  </Box>
                )}

                {jobTitle && (
                  <Box
                    color={`var(${DesignToken.ContentBasicDisabled})`}
                    css={css`
                      font-size: 14px;
                      line-height: 18px;
                    `}
                  >
                    {jobTitle}
                  </Box>
                )}
              </Box>

              {lastMessageSentDate && (
                <Box
                  color={
                    disabled
                      ? `var(${DesignToken.ContentBasicDisabled})`
                      : `var(${DesignToken.ContentBasicSecondary})`
                  }
                  css={(theme: Theme) => css`
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    white-space: nowrap;

                    @media (min-width: ${theme.breakpoints.md}) {
                      font-size: 14px;
                    }
                  `}
                >
                  {lastMessageSentDate}
                </Box>
              )}
            </Box>

            {(lastMessage || draft) && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                css={css`
                  // Ovveride DS styles
                  [class*='lc-Badge-module__badge--primary'] {
                    background-color: var(${DesignToken.DecorRed500});
                  }

                  [class*='lc-Badge-module__badge--medium'] {
                    border-radius: 18px;
                    padding: 2px 6px;
                    min-width: 18px;
                    height: 18px;
                  }
                `}
              >
                {draft?.text &&
                !isActiveItem &&
                ((lastMessage &&
                  lastMessage?.base?.createdAt < draft?.updatedAt) ||
                  !lastMessage) ? (
                  <Box
                    display="flex"
                    gap="8px"
                    alignItems="center"
                    color={`var(${DesignToken.ContentBasicSecondary})`}
                    css={(theme: Theme) => css`
                      font-weight: 400;
                      font-size: 16px;
                      font-style: italic;
                      line-height: 22px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      @media (min-width: ${theme.breakpoints.md}) {
                        font-size: 14px;
                        line-height: 18px;
                      }
                    `}
                    className="data-hj-suppress"
                  >
                    <Box>
                      <Tag kind="purple" size="small">
                        Draft
                      </Tag>
                    </Box>

                    <Box
                      css={css`
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      `}
                      pr="2px"
                    >
                      {parseDraftText(draft.text, agentsLookup)}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    color={
                      isUnread
                        ? `var(${DesignToken.ContentBasicPrimary})`
                        : `var(${DesignToken.ContentBasicSecondary})`
                    }
                    css={(theme: Theme) => css`
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      ${isUnread ? 'font-weight: 600;' : ''}

                      @media (min-width: ${theme.breakpoints.md}) {
                        font-size: 14px;
                        line-height: 18px;
                      }

                      ${disabled
                        ? `color: var(${DesignToken.ContentBasicDisabled});`
                        : ''}
                    `}
                    className="data-hj-suppress"
                  >
                    {lastMessageText}
                  </Box>
                )}

                {notificationCount > 0 && (
                  <Badge
                    size="medium"
                    count={
                      notificationCount > MAX_NOTIFICATIONS
                        ? `${MAX_NOTIFICATIONS}+`
                        : notificationCount
                    }
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </div>
    );
  }
);

SidebarItem.displayName = 'SidebarItem';

export default SidebarItem;
