/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Check } from '@livechat/design-system-icons';
import {
  Button,
  DesignToken,
  Icon,
} from '@livechat/design-system-react-components';

import Box from '@teamchat-shared/design/Box';

type Props = {
  kind?: string;
  label: string;
  isCompleted?: boolean;
  onSubmit: () => void;
  isLoading?: boolean;
};

const OnboardingButton = ({
  kind = 'high-contrast',
  label,
  isCompleted = false,
  onSubmit,
  isLoading = false,
}: Props) => {
  const handleSubmit = async () => {
    await onSubmit();
  };

  return (
    <Box mt="-50px" ml="48px">
      <Button
        loading={isLoading}
        kind={kind}
        onClick={() => {
          if (isCompleted) {
            return;
          }

          handleSubmit();
        }}
        size="large"
        disabled={isCompleted}
        icon={isCompleted ? <Icon source={Check} /> : null}
        css={css`
          ${isCompleted
            ? `
              background: var(${DesignToken.ActionPositiveDisabled});
              border-color: var(${DesignToken.ActionPositiveDisabled});
              color: var(${DesignToken.ContentBasicPositive});

              &[disabled] {
                background: var(${DesignToken.ActionPositiveDisabled});
                border-color: var(${DesignToken.ActionPositiveDisabled});
                color: var(${DesignToken.ContentBasicPositive});
              }

              &:hover {
                background: var(${DesignToken.ActionPositiveDisabled});
                border-color: var(${DesignToken.ActionPositiveDisabled});
                color: var(${DesignToken.ContentBasicPositive});
              }
            
            `
            : ''}
        `}
      >
        {label}
      </Button>
    </Box>
  );
};

export default OnboardingButton;
